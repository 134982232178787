@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: #f5f5f5;
  color: #292b33;
  letter-spacing: -0.03em;
  font-family: 'MMC', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

a {
  padding: 0;
  margin: 0;
}

@screen ms {
  html {
    font-size: 14px;
  }
}

@screen mm {
  html {
    font-size: 17px;
  }
}

@screen ml {
  html {
    font-size: 17px;
  }
}

@screen t {
  html {
    font-size: 18px;
  }
}

@screen dxs {
  html {
    font-size: 11px;
    /* 14px */
  }
}

@screen ds {
  html {
    font-size: 13px;
    /* 15px */
  }
}

@screen dm {
  html {
    font-size: 14px;
    /* 16px */
  }
}

@screen dl {
  html {
    font-size: 17px;
  }
}

@screen dxl {
  html {
    font-size: 20px;
  }
}

@font-face {
  font-family: 'Aldrich';
  src: url('./resources/fonts/Aldrich/Aldrich-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MMC';
  src: url('./resources/fonts/MMC/MMC-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: bold;
  font-display: swap;
}

@keyframes opacity-animate {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.popover-con {
  animation: opacity-animate 0.3s linear;
}

.fadeOut {
  animation: fadeOut 0.3s linear;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.react-tiny-popover-container {
  z-index: 100;
}

@layer utilities {
  .menu-icons-hover-shadow {
    filter: drop-shadow(4px 15px 4px rgba(40, 43, 51, 0.21));
  }

  .menu-icons-active-shadow {
    filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.14));
  }

  .no-filter-shadow {
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0));
  }

  .progress-line-shadow {
    filter: drop-shadow(0px 4px 8px rgba(219, 89, 78, 0.23))
      drop-shadow(0px 4px 20px rgba(219, 89, 78, 0.54));
  }

  .mail-button-shadow {
    box-shadow: 0px 19px 31px rgba(0, 0, 0, 0.15);
  }

  .blue-button-shadow {
    box-shadow: 0px 7px 11px rgba(96, 128, 189, 0.33);
  }

  .no-shadow {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  }

  .no-bg {
    background-image: none;
  }

  .timeline-shadow {
    box-shadow: 0px 4px 31px #db594e;
  }

  .red-button-shadow {
    box-shadow: 0px 2px 14px rgb(219 89 78 / 50%);
  }
  .button-dark-shadow {
    box-shadow: 0px 3px 2px rgb(0 0 0 / 10%);
  }

  .finished-field-red-shadow {
    box-shadow: 12px 16px 25px rgba(200, 4, 4, 0.12);
  }

  .modal-shadow {
    box-shadow: 12px 12px 50px rgba(0, 0, 0, 0.12);
  }

  .markdown p {
    margin: 1.75rem 0;
  }

  .markdown p img:not(:last-child) {
    margin-bottom: 1.75rem;
  }

  .markdown iframe.video,
  .markdown video {
    width: 100%;
    height: 28rem;
  }

  .markdown ul li {
    position: relative;
    margin: 1.1rem 0;
    padding-left: 1.5rem;
  }

  .markdown ul li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0.6rem;
    background: #dc4c4c;
    width: 0.3rem;
    height: 0.3rem;
  }

  .tier-block-shadow-mobile {
    box-shadow: 6px 11px 27px rgba(0, 0, 0, 0.14);
  }

  .tier-button-shadow {
    box-shadow: 0px 5px 7px rgba(78, 118, 219, 0.51);
  }

  .resize-animation-stopper *,
  .resize-animation-stopper {
    animation: none !important;
    transition: none !important;
  }

  .popover-shadow {
    box-shadow: 8px 12px 19px rgba(0, 0, 0, 0.11);
  }
  .active-pool-hover-shadow {
    box-shadow: 0px 84px 42px -53px rgba(0, 0, 0, 0.11),
      0px 27px 23px -11px rgba(0, 0, 0, 0.06);
  }

  .upcoming-pool-hover-shadow {
    box-shadow: 0px 70px 42px -40px rgba(0, 0, 0, 0.11),
      0px 27px 23px -11px rgba(0, 0, 0, 0.06);
  }

  .banner-shadow {
    box-shadow: 0px 15px 22px -6px rgba(0, 0, 0, 0.24),
      0px 5.77778px 7.00741px -6px rgba(0, 0, 0, 0.13),
      0px 1.22222px 1.79259px -6px rgba(0, 0, 0, 0.1);
    /* width: calc(100% - 9.8rem); */
  }
}

/* ::-webkit-scrollbar {
  background: #312F2F;
  width: 0.88rem;
  height: 8px;
  border: none;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: #403E3E; 
  border: none;
  cursor: pointer;
} */

.tab-current {
  position: absolute;
  background-image: linear-gradient(
    180deg,
    #7797e8 0%,
    rgba(119, 151, 232, 0) 100%
  );
  background-size: 100% 2.5rem;
  background-repeat: no-repeat;
  width: 115%;
  height: 3.6rem;
  transform: perspective(1.5rem) rotateX(13deg) translateY(-0.7rem);
  filter: blur(6px);
  opacity: 0.3;
}

.tab-red {
  background-image: linear-gradient(
    180deg,
    #dc4c4c 0%,
    rgba(119, 151, 232, 0) 100%
  );
}

.no-scrollbar {
  scrollbar-width: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

@keyframes fazer1 {
  0% {
    left: 0;
  }
  100% {
    left: -80px;
    opacity: 0;
  }
}

@keyframes fazer2 {
  0% {
    left: 0;
  }
  100% {
    left: -100px;
    opacity: 0;
  }
}

@keyframes fazer3 {
  0% {
    left: 0;
  }
  100% {
    left: -50px;
    opacity: 0;
  }
}

/* Modal bg animation start */

.longfazers > span:nth-child(1) {
  left: 20%;
  animation: lf 0.6s linear infinite;
  animation-delay: -5s;
}
.longfazers > span:nth-child(2) {
  left: 40%;
  animation: lf2 0.8s linear infinite;
  animation-delay: -1s;
}
.longfazers > span:nth-child(3) {
  left: 60%;
  animation: lf3 0.6s linear infinite;
}
.longfazers > span:nth-child(4) {
  left: 80%;
  animation: lf4 0.5s linear infinite;
  animation-delay: -3s;
}

@keyframes lf {
  0% {
    bottom: 200%;
  }
  100% {
    bottom: -200%;
    opacity: 0;
  }
}
@keyframes lf2 {
  0% {
    bottom: 200%;
  }
  100% {
    bottom: -200%;
    opacity: 0;
  }
}
@keyframes lf3 {
  0% {
    bottom: 200%;
  }
  100% {
    bottom: -100%;
    opacity: 0;
  }
}
@keyframes lf4 {
  0% {
    bottom: 200%;
  }
  100% {
    bottom: -100%;
    opacity: 0;
  }
}
/* Modal bg animation end */

.disable-scroll {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

/* React tooltip overries */

.react-tooltip-white {
  color: #292b33 !important;
  font-size: 0.8rem !important;
  background: white !important;
  box-shadow: 0.4rem 1.1rem 0.95rem 0px #0000001c;
  opacity: 1 !important;
  text-transform: initial !important;
  padding: 0.9rem 1.1rem !important;
  z-index: 1000 !important;
  max-width: 17rem !important;
}
.react-tooltip-white.place-top::after {
  border-top-color: white !important;
  border-top-style: white !important;
  border-top-width: 6px !important;
}
.react-tooltip-white.place-bottom::after {
  border-bottom-color: white !important;
  border-bottom-style: white !important;
  border-bottom-width: 6px !important;
}
.react-tooltip-white.place-right::after {
  border-right-color: white !important;
  border-right-style: white !important;
  border-right-width: 6px !important;
}
.react-tooltip-white.place-left::after {
  border-left-color: white !important;
  border-left-style: white !important;
  border-left-width: 6px !important;
}
.react-tooltip-black {
  color: #8b8b8d !important;
  font-size: 0.8rem !important;
  background: #34363f !important;
  box-shadow: 0.4rem 1.1rem 0.95rem 0px #0000001c;
  opacity: 1 !important;
  text-transform: initial !important;
  padding: 0.9rem 1.1rem !important;
  z-index: 1000 !important;
  max-width: 17rem !important;
}
.react-tooltip-black.place-top::after {
  border-top-color: #34363f !important;
  border-top-style: #34363f !important;
  border-top-width: 6px !important;
}
.react-tooltip-black.place-bottom::after {
  border-bottom-color: #34363f !important;
  border-bottom-style: #34363f !important;
  border-bottom-width: 6px !important;
}

.card-backface-safari {
  -webkit-perspective: 0;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  visibility: visible;
  backface-visibility: hidden;
}

@layer utilities {
  .clip-total-earned {
    clip-path: url(#clip-total-earned);
  }

  .divider-line {
    background: linear-gradient(
      270deg,
      rgba(81, 85, 94, 0) 0%,
      #51555e 73.44%,
      rgba(81, 85, 94, 0) 100%
    );
  }
}

#pera-wallet-sign-txn-toast-wrapper {
  z-index: 200;
  position: relative;
}
